import React, { Component } from 'react'; 
import './hourGlass.css';

class HourGlass extends Component { 
    render() {
        // return <img alt="" src="../../images/please-wait.gif" alt="Please wait..." />
        return <img src="/images/please-wait.gif" alt="Please wait..." />
    }
}

export default HourGlass;
